@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&family=Poppins:wght@100;300;400;500;600;700&family=Source+Sans+Pro:wght@400;700&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap'); */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Poppins", "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.topBar {
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.03);
}

/* .mySidebar {
	position: absolute;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	
} */

/* .sideBar-Container {
	height: 100vh;
	display: grid;
	grid-template-rows: 1fr;
} */
.MuiTableRow-root:hover {
  cursor: pointer;
}
