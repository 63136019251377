/* ===========================
   ====== Search Box ====== 
   =========================== */

.search {
	/* border: 2px solid #cf5c3f; */
	overflow: auto;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	margin-bottom: 20px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
	border-radius: 8px;
	height: 45px;
}

.search input[type='text'] {
	border: 0px;
	width: 90%;
	padding: 13.5px 10px 13.5px 10px;
	height: 45px;
}

.search input[type='text']:focus {
	outline: 0;
}

.search input[type='submit'] {
	padding: 13.5px 10px 13.5px 10px;
	height: 45px;
	border: 0px;
	background: transparent;
	/* background-color: #cf5c3f; */
	background-image: url('../assets/Search.png');
	background-repeat: no-repeat;
	background-position: 10px center;
	color: #fff;
	float: left;
	padding: 10px;
	padding-right: 15px;
	margin-left: 8px;
	cursor: pointer;
}

/* ===========================
   ====== Medua Query for Search Box ====== 
   =========================== */

@media only screen and (min-width: 150px) and (max-width: 780px) {
	.search {
		width: 95%;
		margin: 0 auto;
	}
}

/* ===========================
   ====== Type Selectors ====== 
   =========================== */

.type {
	padding: 20px 60px;
}

.type.selected {
	border-bottom: 4px solid #5448b2;
}
