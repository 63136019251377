
.loader {
    box-sizing: border-box;
    display: inline-block;
    width: 50px;
    height: 80px;
    border-top: 5px solid #000;
    border-bottom: 5px solid #000;
    position: relative;
    background: linear-gradient(#FF3D00 30px, transparent 0) no-repeat;
    background-size: 2px 40px;
    background-position: 50% 0px;
    animation: spinx 5s linear infinite;
  }
  .loader:before, .loader:after {
    content: "";
    width: 40px;
    left: 50%;
    height: 35px;
    position: absolute;
    top: 0;
    transform: translatex(-50%);
    background: rgba(78, 78, 78, 0.127);
    border-radius: 0 0 20px 20px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 0px;
    animation: lqt 5s linear infinite;
  }
  .loader:after {
    top: auto;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    animation: lqb 5s linear infinite;
  }
  @keyframes lqt {
    0%, 100% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0% 0px;
    }
    50% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0% 40px;
    }
    50.1% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0% -40px;
    }
  }
  @keyframes lqb {
    0% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0 40px;
    }
    100% {
      background-image: linear-gradient(#FF3D00 40px, transparent 0);
      background-position: 0 -40px;
    }
  }
  @keyframes spinx {
    0%, 49% {
      transform: rotate(0deg);
      background-position: 50% 36px;
    }
    51%, 98% {
      transform: rotate(180deg);
      background-position: 50% 4px;
    }
    100% {
      transform: rotate(360deg);
      background-position: 50% 36px;
    }
  }
      