.imageUploader-gift {
    background: #FFFFFF url(../../assets/gifUploader.svg) no-repeat right center;
    background-position-x: calc(100% - 15px);
  }
  
  .dateInputPicker-gift {
    background: #EBECF5 url(../../assets/datePicker.svg) no-repeat right center;
    background-position-x: calc(100% - 15px);
  
    width: 100%;
    padding: 10px 0px 10px 14px;
    border: 1px solid #C7C7C7;
    border-radius: 8px;
  }